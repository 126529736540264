.features__section {
  overflow: hidden; }

.features__section--item {
  padding-bottom: 148px;
  position: relative;
  z-index: 20;
  &:last-child {
    padding-bottom: 15px;
    &:after {
      bottom: -30%; } }
  &:after {
    content: '';
    display: block;
    height: 500px;
    width: 767px;
    background: url("../img/backgrounds/oval_bg.png") 0 0 no-repeat;
    position: absolute;
    left: 0;
    bottom: -110px;
    z-index: -1; }
  &:nth-child(even) {
    &:after {
      left: auto;
      right: 0;
      transform: scale(-1, 1);
      filter: FlipH; } } }

.features__title {
  color: $c-purple;
  font: 400 34px/1 'Roboto Slab', Arial, Helvetica, sans-serif;
  margin: 0 0 20px;
  position: relative;
  z-index: 0; }

%features__number {
  display: inline-block;
  height: 88px;
  width: 170px;
  position: absolute;
  left: -48px;
  top: -58px;
  z-index: -1; }

@for $i from 1 through 4 {
  .features__number--#{$i} {
    background: url("../img/numbers/0#{$i}_image.png") 0 0 no-repeat;
    @extend %features__number;

    @include retina {
      background: url("../img/numbers/0#{$i}_image@2x.png") 0 0 no-repeat; } } }
