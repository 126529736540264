/* Main Header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all .3s ease;
  z-index: 1000;
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    transition: all .3s ease;
    @include tablet-l {
      padding: 12px 15px; } }
  a {
    color: $c-white; } }

.header__logo {
  margin-right: auto;
  a {
    display: block;
    font-size: 0; }
  svg {
    fill: currentColor; } }

.main-menu__nav {
  @include mobile-l {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    background: rgba(238,238,238,0.8);
    transition: transform .4s ease, opacity .2s ease;
    transform: translateY(-25px);
    opacity: 0;
    height: 0;
    padding: 1px 0 0;
    overflow: hidden;
    pointer-events: none;
    &.open {
      transform: translateY(0);
      opacity: 1;
      height: auto;
      padding: 12px 0 15px;
      pointer-events: auto; } } }


.main-menu__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 51px 0 0;
  @include tablet-l {
    margin: 0; }
  @include mobile-l {
    display: block; }

  li {
    padding: 0 22px 0 21px;
    text-align: center;
    @include tablet-l {
      &:last-child {
        padding-right: 0; } }
    @include mobile-l {
      padding: 0; } }
  a {
    display: inline-block;
    font-size: 18px;
    line-height: 29px;
    font-weight: 500;
    padding: 5px 0 6px;
    transition: all .3s ease;
    @include mobile-l {
      color: $c-blue; }
    &:hover {
      border-bottom: 1px solid $c-white;
      text-decoration: none; }
    @include mobile-l {
      &:not(.btn--secondary):hover {
        border-bottom: none; }
      &:hover {
        color: $c-purple; } }
    &.btn--secondary {
      @include mobile-l {
        border-color: $c-blue; } } } }


.sticky {
  background: rgba(250,250,250,0.7);
  @include mobile-l {
    background: rgba(250,250,250,0.95); }
  .inner {
    padding: 15px 0;
    @include tablet-l {
      padding: 12px 15px; } }
  a {
    color: $c-purple; }
  .main-menu__list {
    a {
      &:hover {
        border-bottom-color: $c-purple; }
      &.btn--secondary {
        border-color: $c-purple; } } }
  .m_nav--button {
    .lines {
      background: $c-purple;
      &:before,
      &:after {
        background: $c-purple; } } } }

.m_nav--button {
  float: right;
  background: 0 0;
  border: none;
  z-index: 100;
  cursor: pointer;
  height: 46px;
  padding: 0 9px;
  transition: all .3s ease;
  display: none;
  @include mobile-l {
    display: block; }
  .lines {
    background: $c-white;
    display: inline-block;
    height: 4px;
    width: 30px;
    position: relative;
    top: -3px;
    transition: all .3s ease;
    &:before,
    &:after {
      background: $c-white;
      content: '';
      display: inline-block;
      height: 4px;
      width: 30px;
      position: absolute;
      left: 0;
      transform-origin: 2.57px center 0;
      transition: all .3s ease; }
    &:before {
      top: 9px; }
    &:after {
      top: -9px; } }
  &.active {
    .lines {
      &:before {
        top: 6px; }
      &:after {
        top: -6px; } } } }
