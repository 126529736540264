/* Helper elements & classes */
a {
  text-decoration: none;
  transition: color .2s ease;
  &:hover {
    text-decoration: underline; } }

img {
  height: auto;
  max-width: 100%;
  &.cover {
    height: 100%;
    object-fit: cover; } }

/* Classes */
.show-for-mobile {
  display: none;
  @media mobile-l {
    display: block; } }
.hide-for-mobile {
  @media mobile-l {
    display: none; } }

.hide {
  display: none !important; }
.show {
  display: block !important; }

/* text align */
.a-center {
  text-align: center; }
.a-left {
  text-align: left; }
.a-right {
  text-align: right; }

/* buttons */
.btn {
  display: inline-block;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px 0;
  padding: 5px 19px 6px 18px;
  width: 161px;
  text-align: center;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease;
  &:hover {
    text-decoration: none; }

  &--primary {
    border: none;
    background: $c-blue;
    color: $c-white;
    line-height: 29px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    &:hover {
      &:before {
        transform: translate(-100%, 0); } }
    &:before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;

      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      background-image: linear-gradient(-90deg, $c-blue 0%, $c-purple 100%);
      transition: transform .4s ease; } }

  &--secondary {
    background: transparent;
    color: $c-white;
    border-color: $c-white;
    &:hover {
      background: $c-white;
      color: $c-purple;
      border-color: $c-purple; } } }

/* App buttons */
.app__links {
  padding: 50px 0 0;
  a {
    svg {
      height: 58px;
      width: 193px; }
    .hover {
      display: none; }
    &:hover {
      & > svg, img {
        display: none; }
      & > .hover {
        display: block;
        z-index: 100; } } }
  a {
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    height: 60px;
    width: 195px;
    position: relative;
    overflow: hidden;
    @include tablet-l {
      margin-bottom: 15px; } }

  .app__link--ios {
    margin-right: 25px;
    @include mobile-p {
      margin-right: 0; } } }

/* International flags dropdown */
.intl-tel-input {
  border-bottom: 1px solid #E0E2E9;
  margin: 0 15px 0 0; }

.iti-flag {
  background-image: url("../img/flags.png"); }

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("../img/flags@2x.png"); } }
