.get-started__section {
  background: url("../img/backgrounds/footer_mask.svg") center top no-repeat;
  background-size: 1960px auto;
  color: $c-white;
  padding: 405px 0 0;
  transform: translateY(-125px);
  position: relative;
  z-index: 10;
  @include retina {
    background-image: url("../img/backgrounds/footer_mask_2560.svg");
    background-size: 100% auto; }
  @include tablet-p {
    padding-top: 335px; }
  &:before {
    content: '';
    background: $c-white;
    height: 300px;
    width: 100%;
    position: absolute;
    left: 0;
    top: -300px;
    z-index: -1; }
  .app__links {
    padding: 0; }
  .app__link--ios,
  .app__link--android {
    border: 1px solid $c-white;
    &:hover {
      background: $c-white; } } }

.get-started__title {
  color: $c-white; }

.get-started__description {
  margin: 0 auto 40px;
  max-width: 490px;
  p {
    margin: 0; } }
