/* Top Banner */
.form-actions__section {
  background: url("../img/backgrounds/header_mask_1900.svg") center top no-repeat;
  background-size: 1960px auto;
  color: $c-white;
  @include retina {
    background-image: url("../img/backgrounds/header_mask_2560.svg");
    background-size: 100% auto; }
  &:before {
    content: '';
    background: $c-white;
    height: 300px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 500px;
    z-index: -1; }
  .app__links {
    @include mobile-l {
      text-align: center; } }
  .app__link--ios,
  .app__link--android {
    border: 1px solid $c-purple;
    &:hover {
      background: $c-purple; } } }

.form-actions__inner {
  padding: 175px 95px 175px 80px;
  text-align: center;
  @include tablet-p {
    height: auto;
    padding: 125px 20px; } }
.form-actions__col {
  &--left {
    width: 52%;
    flex: 0 0 52%;
    @include mobile-l {
      width: 100%;
      flex: 0 0 100; } }
  &--right {
    width: 48%;
    flex: 0 0 48%;
    @include tablet-p {
      width: 28%;
      flex: 0 0 28%; }
    @include mobile-l {
      padding-top: 30px;
      width: 100%;
      flex: 0 0 100;
      text-align: center; } } }
.form-actions__title {
  margin: 0 0 35px;
  padding: 115px 0 0; }

.sms__form {
  color: $c-dark;
  display: flex;
  @include mobile-p {
    display: block; }

  input[type=text],
  input[type=tel] {
    background: none;
    border: none;
    padding-top: 11px; }
  button {
    border: none;
    background: none;
    line-height: 0;
    padding: 0; }
  .btn {
    margin: 0;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed; } } }

.success-message,
.error-message {
  font-size: 12px;
  margin: 15px 0 0; }

.success-message {
  color: #32cd32; }

.error-message {
  color: #ff0000; }

.sms__limit--warning {
  color: $c-dark;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0 0 5px; }
