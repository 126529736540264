/* Grid */
.row {
  display: flex;
  justify-content: space-between;
  .col-2 {
    width: calc(50% - 20px);
    flex: 0 0 calc(50% - 20px); }
  .col-3 {
    width: calc(33.333% - 20px);
    flex: 0 0 calc(33.333% - 20px); }
  .col-4 {
    width: calc(25% - 22.5px);
    flex: 0 0 calc(25% - 22.5px); }

  //mobile
  @include tablet-p {
    flex-wrap: wrap;
    .col-2--tablet {
      width: calc(50% - 15px);
      flex: 0 0 calc(50% - 15px); } }
  @include mobile-l {
    flex-direction: column;
    .col--mobile {
      width: 100%;
      flex: 0 0 100%; } }

  //columns with no padding
  &--no-padding {
    .col-2 {
      width: 50%;
      flex: 0 0 50%; }
    .col-3 {
      width: 33.333%;
      flex: 0 0 33.333%; }
    .col-4 {
      width: 25%;
      flex: 0 0 25%; }
    @include mobile-l {
      flex-direction: column;
      .col--mobile {
        width: 100%;
        flex: 0 0 100%; } } }

  // content vertical aligned to center
  &--vertical-middle {
    align-items: center; }

  // content horizontally aligned to center
  &--horizontal-middle {
    justify-content: center; }

  // reverse columns
  &--reverse {
    flex-direction: row-reverse;
    @include mobile-l {
      flex-direction: column; } } }
