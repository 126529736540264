*, :before, :after {
  box-sizing: border-box; }

@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900');


/* Placeholder on focus */
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
    color: $c-light; }
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
    color: $c-light; }
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
    color: $c-light; }
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
    color: $c-light; }

/* Wrappers */

body {
  margin: 0;
  padding: 0;
  color: $c-dark;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  background: $c-white url("../img/backgrounds/bg_1920.jpg") -35px -130px no-repeat;
  background-size: 2040px 617px;
  @include retina {
    background-image: url("../img/backgrounds/bg_2560.jpg");
    background-size: 2640px auto;
    font-size: 18px; }
  &.chrome {
    transition: background .2s ease;
    #site-main {
      transition: background .2s ease; } } }


.page-wrapper {
  margin: 0 auto;
  width: 100%; }

#site-main {
  background: $c-white url("../img/backgrounds/bg_1920.jpg") -35px bottom no-repeat;
  background-size: 2040px 780px;
  @include retina {
    background-image: url("../img/backgrounds/bg_2560.jpg");
    background-size: 2640px auto; } }

.inner {
  margin: 0 auto;
  width: 100%;
  max-width: 1110px;
  @include desktop {
    padding: 0 15px; } }

/* Typography */
h1 {
  color: $c-purple;
  font: 400 34px/1 'Roboto Slab', Arial, Helvetica, sans-serif;
  margin: 0 0 .875em;
  @include retina {
    font-size: 44px; }
  @include tablet-p {
    font-size: 32px;
    padding: 0; }
  @include mobile-p {
    font-size: 28px; } }

h2 {
  color: $c-purple;
  font: 400 34px/1 'Roboto Slab', Arial, Helvetica, sans-serif;
  margin: 0 0 60px;
  text-align: center;
  @include retina {
    font-size: 44px; } }

h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3; }

strong {
  font-weight: 700; }

ul {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.content {
  ul {
    list-style: disc inside;
    margin: 0 0 30px;
    padding-left: 30px; } }
