/** Contact Us section */
.contact-us__section {
  background: url("../img/backgrounds/contact-us-bg.png") center 0 no-repeat;
  position: relative;
  z-index: 100;
  .inner {
    max-width: 992px; }
  @include desktop-xl {
    .inner {
      max-width: 768px; } }
  @include desktop {
    .inner {
      max-width: 539px; } } }

.contact-us__from {
  width: 100%;
  .row,
  .form-group {
    width: 100%; }
  .form-group {
    margin-bottom: 60px;
    @include mobile-l {
      margin-bottom: 40px; } }
  button {
    border: none;
    display: inline-block;
    font-size: 0;
    padding: 0;
    span {
      margin: 0; } } }

.form-input {
  border: none;
  border-bottom: 1px solid $c-lightblue;
  height: 32px;
  width: 100%;
  resize: none;
  &:focus {
    border-bottom: 2px solid $c-blue;
    outline: none; } }

#comment-area {
  height: 102px;
  max-height: 32px;
  transition: max-height .3s ease-out;
  &.focused {
    max-height: 102px;
    transition: max-height .3s ease-in; } }
