// fade-in effect
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8); }
  100% {
    opacity: 1;
    transform: scale(1); } }
%fadeIn {
  animation: fadeIn ease 1.2s; }

// bounce effect
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-7px); }
  60% {
    transform: translateY(-2px); } }
%bounce {
  animation: bounce 1.2s infinite; }

//scale effect
@keyframes scale {
  0%, 20%, 50%, 80%, 100% {
    transform: scale(1); }
  40% {
    transform: scale(0.8); }
  60% {
    transform: scale(0.9); } }
%scale {
  animation: scale 1.2s infinite; }

// rotate 360deg effect
@keyframes rotate360 {
  0% {
    transform: translateY(0) rotateX(0deg); }
  50% {
    transform: translateY(0) rotateX(180deg); }
  100% {
    transform: translateY(0) rotateX(360deg); } }
%rotate360 {
  animation: rotate360 ease .2s 4; }

// rotateX 360deg effect
@keyframes rotateZ360 {
  from {
    transform: rotateZ(0deg); }
  to {
    transform: rotateZ(360deg); } }
%rotateX360 {
  animation: rotateZ360 linear .6s infinite; }
