/* Tables */
table {
  color: $c-dark;
  page-break-before: always;
  padding-top: 8px;
  width: 100%;
  table-layout: fixed;
  thead {
    tr {
      padding: 5px; }
    td {
      font-weight: bold; } }
  td {
    padding: 5px;
    text-align: center;
    word-wrap: break-word;
    @include mobile-l {
      position: relative;
      padding-left: 50%;
      &:before {
        content: attr(data-title);
        position: absolute;
        top: 5px;
        left: 5px;
        width: 45%;
        padding-right: 5px;
        white-space: nowrap; } } }
  a {
    color: $c-dark;
    text-decoration: underline;
    &:hover {
      text-decoration: none; } } }
