//media query
$mobile-portrait: 479px;
$mobile-landscape: 767px;
$tablet-portrait: 992px;
$tablet-landscape: 1024px;
$desktop: 1110px;
$desktop-xl: 1420px;

@mixin mobile-p {
  @media (max-width: #{$mobile-portrait}) {
    @content; } }

@mixin mobile-l {
  @media (max-width: #{$mobile-landscape}) {
    @content; } }

@mixin tablet-p {
  @media (max-width: #{$tablet-portrait}) {
    @content; } }

@mixin tablet-l {
  @media (max-width: #{$tablet-landscape}) {
    @content; } }

@mixin desktop {
  @media (max-width: #{$desktop}) {
    @content; } }

@mixin desktop-xl {
  @media (max-width: #{$desktop-xl}) {
    @content; } }

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 2000px), only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 2000px), only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 2000px), only screen and (min-device-pixel-ratio: 2) and (min-width: 2000px), only screen and (min-resolution: 192dpi) and (min-width: 2000px), only screen and (min-resolution: 2dppx) and (min-width: 2000px) {
    @content; } }
